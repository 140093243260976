export const environment = {
    production: false,
    firebaseConfig: {
        apiKey: "AIzaSyDjfX2cRUM1G65P7BECWvA0RH8EBY4Yvzk",
        authDomain: "codingclub-5666c.firebaseapp.com",
        projectId: "codingclub-5666c",
        storageBucket: "codingclub-5666c.appspot.com",
        messagingSenderId: "818777602500",
        appId: "1:818777602500:web:4f07748688cc90315bcc91",
        measurementId: "G-TP9YYE1L5N"
    }
};
