import {Component, OnInit} from '@angular/core';
import {Capacitor, Plugins} from '@capacitor/core';
import {IonRouterOutlet, NavController, Platform} from '@ionic/angular';
import {Constants} from 'src/utils/constants';
import {AppStorage} from 'src/providers/data/appstorage';
import {Router} from '@angular/router';
import {MessageHelper} from '../providers/message-helper';
import {PushNotification, PushNotificationToken, PushNotificationActionPerformed} from '@capacitor/core';
import { Location } from '@angular/common';
import * as firebase from 'firebase/app';
import 'firebase/analytics';
import {environment} from '../environments/environment.prod';
import {HttpProvider} from "../providers/data/data";
const {SplashScreen, StatusBar, App, PushNotifications, Modals, Device} = Plugins;

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
    token: any;
    deviceId: any;
    // isNative: any;
    constructor(
        private platform: Platform,
        private storage: AppStorage,
        public navCtrl: NavController,
        private route: Router,
        public http: HttpProvider,
        private msgHelper: MessageHelper,
        private _location: Location
    ) {
        this.platform.ready().then(() => {
            this.initializeApp();
            this.closeApp();
        });
    }

    async initializeApp() {
        const firebaseConfig = environment.firebaseConfig;
        firebase.initializeApp(firebaseConfig);
        firebase.analytics();
        // this.isNative = Capacitor.isNative;
        // console.log('app android---',this.isNative);
        const getPtf = this.msgHelper.checkPlatform();
        if (getPtf) {
            StatusBar.setBackgroundColor({color: '#000000'}).catch((e) => console.log(e));
            setTimeout(() => SplashScreen.hide(), 1000);
        }
        const info = await Device.getInfo();
        this.deviceId = info.uuid;
        this.storage.setValue(Constants.UUID, this.deviceId);
        // console.log('device Id: ' + this.deviceId);
        this.storage.getValue(Constants.TOKEN).then(token => {
            this.token = token;
            if (this.token != null && this.token !== 1) {
                this.navCtrl.navigateRoot('/menu/home', {animationDirection: 'forward'});
            } else {
                // if (this.msgHelper.checkPlatform()) {
                //     this.navCtrl.navigateRoot('/slides', {animationDirection: 'forward'});
                // } else {
                //     this.navCtrl.navigateRoot('/start', {animationDirection: 'forward'});
                //     // this.navCtrl.navigateRoot('/social-login', {animationDirection: 'forward'});
                //     //this.navCtrl.navigateRoot('/login', {animationDirection: 'forward'});
                // }
            }
        });
    }

    ngOnInit() {

        const checkPtf = this.msgHelper.checkPlatform();
        if (checkPtf) {
            // console.log('Initializing HomePage');

            // Register with Apple / Google to receive push via APNS/FCM
            PushNotifications.register();

            // On succcess, we should be able to receive notifications
            PushNotifications.addListener('registration',
                (token: PushNotificationToken) => {
                    // alert('Push registration success, token: ' + token.value);
                    // console.log('Push registration success, token: ' + token.value);
                    this.storage.setValue(Constants.FCMTOKEN, token.value);
                }
            );

            // Some issue with our setup and push will not work
            PushNotifications.addListener('registrationError',
                (error: any) => {
                    // alert('Error on registration: ' + JSON.stringify(error));
                    // console.log('Error on registration: ' + JSON.stringify(error));
                }
            );

            // Show us the notification payload if the app is open on our device
            PushNotifications.addListener('pushNotificationReceived',
                (notification: PushNotification) => {
                    const audio1 = new Audio('assets/audio.mp3');
                    // console.log('Audio');
                    audio1.play();
                    // alert('Push received: ' + JSON.stringify(notification));
                    // console.log('Push received: ', notification);

                    const alertRet = Modals.alert({
                        title: notification.title,
                        message: notification.body
                    });

                }
            );

            // Method called when tapping on a notification
            PushNotifications.addListener('pushNotificationActionPerformed',
                (notification: PushNotificationActionPerformed) => {
                    // alert('Push action performed: ' + JSON.stringify(notification));
                    // console.log('Push action performed: ' + notification);
                }
            );
        }
    }
    closeApp() {
        App.addListener('backButton', () => {
            const url = this.route.url;
            if (url.includes('/menu/home')) {
                this.msgHelper.showConfirm('Do you want to exit?', 'Exit', 'Yes', 'No').then(confirm => {
                    if (confirm.value) {
                        App.exitApp();
                    }
                });
            }else if(url.includes('/social-login')){
                this.msgHelper.showConfirm('Do you want to exit?', 'Exit', 'Yes', 'No').then(confirm => {
                    if (confirm.value) {
                        App.exitApp();
                    }
                });
            }
            else if(url.includes('/slides')){
                this.msgHelper.showConfirm('Do you want to exit?', 'Exit', 'Yes', 'No').then(confirm => {
                    if (confirm.value) {
                        App.exitApp();
                    }
                });
            }
            else if(url.includes('/login')){
                this.msgHelper.showConfirm('Do you want to exit?', 'Exit', 'Yes', 'No').then(confirm => {
                    if (confirm.value) {
                        App.exitApp();
                    }
                });
            }
        });
    }
}
