import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/start',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'start',
    loadChildren: () => import('./start/start.module').then( m => m.StartModule)
  },
  {
    path: 'menu',
    loadChildren: () => import('./menu/menu.module').then( m => m.MenuPageModule)
  },
  {
    path: 'user-register',
    loadChildren: () => import('./user-register/user-register.module').then( m => m.UserRegisterPageModule)
  },
  {
    path: 'slides',
    loadChildren: () => import('./slides/slides.module').then( m => m.SlidesPageModule)
  },
  {
    path: 'user-details',
    loadChildren: () => import('./user-details/user-details.module').then( m => m.UserDetailsPageModule)
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./forgot-password/forgot-password.module').then( m => m.ForgotPasswordPageModule)
  },
  {
    path: 'otp',
    loadChildren: () => import('./otp/otp.module').then( m => m.OTPPageModule)
  },
  {
    path: 'otpform',
    loadChildren: () => import('./otpform/otpform.module').then( m => m.OTPformPageModule)
  },
  {
    path: 'social-login',
    loadChildren: () => import('./social-login/social-login.module').then( m => m.SocialLoginPageModule)
  },  {
    path: 'single-exam',
    loadChildren: () => import('./single-exam/single-exam.module').then( m => m.SingleExamPageModule)
  },
  {
    path: 'result-test',
    loadChildren: () => import('./result-test/result-test.module').then( m => m.ResultTestPageModule)
  },
  {
    path: 'exam-instruction',
    loadChildren: () => import('./exam-instruction/exam-instruction.module').then( m => m.ExamInstructionPageModule)
  },

  


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, useHash: true })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
