import {Injectable} from '@angular/core';
import {Storage} from '@ionic/storage';

@Injectable()
export class AppStorage {

  constructor(public storage: Storage) {
  }

  getValue(key: string): Promise<any> {
    return this.storage.get(key);
  }

  setValue(key: string, value: string): Promise<any> {
    return this.storage.set(key, value);
  }
  removeValue(key: string,): Promise<any> {
    return this.storage.remove(key);
  }
}
