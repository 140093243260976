import {LoadingController, AlertController} from '@ionic/angular';
import {Injectable} from '@angular/core';
import {ToastController} from '@ionic/angular';
import {Plugins, Capacitor} from '@capacitor/core';
import {MarketingService} from '../app/services/marketing.service';
import {thistle} from "color-name";

const {Modals, Share, Toast} = Plugins;

@Injectable()
export class MessageHelper {
    constructor(public alertCtrl: AlertController, public  loadingCtrl: LoadingController, public toastCtrl: ToastController,
                private marketing: MarketingService) {
    }
    isLoading = false;
    async present() {
        this.isLoading = true;
        return await this.loadingCtrl.create({
            duration: 5000,
        }).then(a => {
            a.present().then(() => {
                console.log('presented');
                if (!this.isLoading) {
                    a.dismiss().then(() => console.log('abort presenting'));
                }
            });
        });
    }


    async dismiss() {
        this.isLoading = false;
        return await this.loadingCtrl.dismiss().then(() => console.log('dismissed'));
    }



    async presentToast(message: string, autoClose: boolean = true) {
        if (Capacitor.isNative == true) {
            await Toast.show({
                text: message,
                position: 'bottom'
            });
        }else {
            const toast = await this.toastCtrl.create({
                message,
                duration: 2000
            });
            toast.present();
        }
    }

    async presentToast1(message: string, autoClose: boolean = true) {
        if (Capacitor.isNative == true) {
            await Toast.show({
                text: message,
                position: 'top',
                

            });
        } else {
            const toast = await this.toastCtrl.create({
                message,
                position: 'top',
                cssClass: "backtoast",
                color: 'danger',
                duration: 2000
            });
            toast.present();
        }
    }

    async presentAlertok(title: string, msg: string, button: string = 'Close') {
        if (Capacitor.isNative == true) {
            let alertRet = await Modals.alert({
                title: title,
                message: msg,
                buttonTitle: 'Ok'
            });
        }else {
            const alert = await this.alertCtrl.create({
                header: title,
                message: msg,
                buttons: [{
                    text: 'Ok'
                }]
            });
            await alert.present();
        }
    }


    async presentAlertPayment(title: string, msg: string, button: string = 'Close') {
            const alert = await this.alertCtrl.create({
                header: title,
                message: msg,
                buttons: [{
                    text: 'Ok'
                }]
            });
            await alert.present();
    }

    async presentLoading(message: string = 'Please wait...') {
        const loading = await this.loadingCtrl.create({
            message,
            duration: 500
        });
        await loading.present();
    }

    async presentAlert(title: string, msg: string, yesButton: string = 'Yes', yesHandler, noButton = 'No', noHandler = null) {
        const alert = await this.alertCtrl.create({
                header: title,
                message: msg,
                buttons: [{
                    text: noButton,
                    handler: noHandler
                }, {
                    text: yesButton,
                    handler: yesHandler
                }]
            });
            await alert.present();
    }

    async presentAlertsOK(title: string, msg: string, yesButton: string = 'Ok', yesHandler, noHandler = null) {

        if (Capacitor.isNative == true) {
            let confirmRet = await Modals.confirm({
                title: title,
                message: msg,
                okButtonTitle: yesButton,
            });
        }else {
            const alert = await this.alertCtrl.create({
                header: title,
                message: msg,
                buttons: [{
                    text: yesButton,
                    handler: yesHandler
                }]
            });
            await alert.present();
        }
    }



    async showConnectionErrorDialog() {
        if (Capacitor.isNative == true) {
            let alertRet = await Modals.alert({
                title: 'Connection Error!',
                message: 'Could not connect to server. Please verify Internet connectivity and try again.',
                buttonTitle: 'Close'
            });
        }else {
            const alert = await this.alertCtrl.create({
                header: 'Connection Error!',
                message: 'Could not connect to server. Please verify Internet connectivity and try again.',
                buttons: ['Close']
            });
            await alert.present();
        }
    }

     showConfirm(message: string, title: string, okBtn = 'Yes', cancelBtn = 'No') {
        return Modals.confirm({
            title,
            message,
            okButtonTitle: okBtn,
            cancelButtonTitle: cancelBtn
        });
    }

    showConfirmOK(message: string, title: string, okBtn = 'Ok') {
        return Modals.confirm({
            title,
            message,
            okButtonTitle: okBtn,
        });
    }
    shareApp() {
        this.marketing.shareEvent();
        return Share.share({
            title: 'POCCOMU',
            url: 'https://play.google.com/store/apps/details?id=codingclubcandidateindapp.zreyas.com',
            dialogTitle: 'Share with buddies'
        });
    }

    checkPlatform() {
        return Capacitor.isNative;
    }
}
