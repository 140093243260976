import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import {MessageHelper} from 'src/providers/message-helper';
import {HttpProvider} from 'src/providers/data/data';
import {AppStorage} from 'src/providers/data/appstorage';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {IonicStorageModule} from '@ionic/storage';
import {MathJaxModule} from 'ngx-mathjax';
import { NgxIonicImageViewerModule } from 'ngx-ionic-image-viewer';
import {LogicProvider} from "../providers/logic";
import { TokenInterceptor } from './token.interceptor';
import {SafePipeModule} from "safe-pipe";
import { SafeHtmlPipe } from './safe-html.pipe';




@NgModule({
  declarations: [AppComponent, SafeHtmlPipe],
  entryComponents: [],
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule,
    HttpClientModule,
    SafePipeModule,
    IonicStorageModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    NgxIonicImageViewerModule
    // MathJaxModule.forRoot({
    //   version: '2.7.5',
    //   config: 'TeX-AMS_HTML',
    //   hostname: 'cdnjs.cloudflare.com'
    // })
  ],
  providers: [
    StatusBar,
    SplashScreen,
    HttpProvider,
    MessageHelper,
    AppStorage,
    LogicProvider,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy, },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
