import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import {IData} from "../interfaces/data.interface";

@Injectable()
export class LogicProvider {

    private dataUrl: string = "assets/countries.json"

      constructor(private http: HttpClient) { }

      getData(): Observable<IData[]> {
        return this.http.get<IData[]>(this.dataUrl)
     }

}